import React, { useContext } from 'react';
import './HeaderBanner.css';
import { Context } from '../../globalState/context/context';


const HeaderBanner = () => {
  const context = useContext(Context);

  const closeBannerHandler = (e) => {
    context.dispatch({ type: 'HEADER_BANNER', data: false });
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div className="header_banner">
      <div className="header_banner__text">
          Оставайтесь в курсе главных новостей лизинга - подписывайтесь!
      </div>

      <div
        className="header_banner__close"
        onClick={closeBannerHandler}
      >
        <img
          src={require('../../assets/mini-close.svg').default}
          alt="Закрыть"
        />
      </div>
    </div>
  );
};

export default HeaderBanner;