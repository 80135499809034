import React, {useContext} from 'react';
import './Layout.css';
import Cookies from 'js-cookie';
import Container from '../components/Container/Container';
import Header from '../components/Header/Header';
// import HeaderBanner from '../components/HeaderBanner/HeaderBanner';
import Footer from '../components/Footer/Footer';
import StickyBox from 'react-sticky-box';
import MobileMenu from '../components/MobileMenu/MobileMenu';
import {Context} from '../globalState/context/context';
import HeaderBanner from "../components/HeaderBanner/HeaderBanner";
import CookieModal from "./CookieModal";


function Layout({children}) {
    const context = useContext(Context);

    return (
        <div className="layout">
            <StickyBox offsetTop={0} offsetBottom={20} className="layout__stycky">
                <Container background='#fff'>
                    <Header/>
                </Container>

                {context.state.stateHeaderBanner
                    && (
                        <a
                            href="https://site.deltaleasing.ru/news-digest"
                            className="layout__banner"
                            target='_blank'
                            rel="noreferrer"
                        >
                            <Container background='#044c9f'>
                                <HeaderBanner/>
                            </Container>
                        </a>
                    )}
            </StickyBox>

            {/* скрытый тег для SEO */}
            <h1 className='h1_hide'>
                ДельтаЛизинг - лизинг для любого бизнеса. Оборудование, спецтехника, транспорт на выгодных условиях.
            </h1>

            <div className="layout__content">
                {children}
            </div>

            <Container>
                <Footer/>
            </Container>

            {
                !Cookies.get('agree_cookies_promo') &&
                <CookieModal />
            }

            <MobileMenu state={context.state}/>
        </div>
    );
}

export default Layout;